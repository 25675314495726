<template>
  <div class="toast-container pb-8">
    <transition-group name="list">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        :class="`border-0 toast shadow-sm rounded mx-3 mt-3 ${
          toast.type ? 'bg-' + toast.type + '-light' : ''
        }`"
      >
        <div class="toast-body pt-1 pb-2 text-dark">
          <div class="d-flex justify-content-between align-items-center">
            <strong class=""
              ><font-awesome-icon class="mr-1 mt-1" icon="bell" />
              {{ toast.title || "Attention" }}</strong
            >
            <div class="">
              <button
                type="button"
                class="ml-2 mb-1 close"
                data-dismiss="toast"
                aria-label="Close"
              >
                <span @click="hideToast(toast.id)">&times;</span>
              </button>
            </div>
          </div>
          <div v-html="sanitize(toast.message)"></div>
        </div>
        <div class="small text-right pr-2 pb-2">
          {{ toast.timestamp }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { sanitizeHTML } from "@/services/helpers";

export default {
  name: "Toast",
  setup() {
    const sanitize = sanitizeHTML;
    const store = useStore();

    const toasts = computed(() => {
      return store.getters.getToasts;
    });

    const hideToast = (toastId) => {
      store.dispatch("hideToast", toastId);
    };

    return {
      toasts,
      hideToast,
      sanitize,
    };
  },
};
</script>

<style scoped>
.toast {
  opacity: 1;
  width: 230px;
  pointer-events: auto;
}
</style>
